import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";
import styles from "./LossesCad.module.css";

//material ul imports
import { useTheme } from "@emotion/react";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Modal,
	Slide,
	TableHead,
	TableSortLabel,
	Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ptBR } from "@mui/material/locale";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import HelpButtonInput from "../../../components/HelpButtonInput";
import InputData from "../../../components/InputData";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";

import {LossService} from "../../../service/loss_service";
import {CropSeasonService} from "../../../service/crop_season_service";
import {HarvestService} from "../../../service/harvest_service";
import Loading from "../../../components/Loading";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));

const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	m: 0,
	p: 0,
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	height: "70vh",
	maxHeight: "80vh",
	transform: "translate(-50%, -50%)",
};
function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};
const columnForActiveSafra = [
	{
		id:"nome",
		label: "Nome",
	},
	{
		id:"data_plantio",
		label:"Data Plantio",
	},
]; 
const columnForActiveLote = [
	{
		id: "cropSeason",
		label:"Safras",
	},
	{
		id: "harvest",
		label:"Lote",
	},
	{
		id:"date",
		label: "Data de Colheita",
	},
]; 

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

function EnhancedTableHead(props) {
	const {  order, orderBy,  onRequestSort,column } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>			
			<TableRow>
				{column.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={ "left"}
						padding={ "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
function LossesCad() {
	const [searchParams] = useSearchParams();
	const [selectType, setSelectType] = useState("0");
	const [selectSafra, setSelectSafra] = useState("0");
	const [selectLote, setSelectLote] = useState("0");
	const [id, setId] = useState(null);
	const [idRowLote, setIdRowLote] = useState(null);
	const [idRowSafra, setIdRowSafra] = useState(null);
	const [allLossesType, setAllLossesType] = useState([]);
	const [allSafras, setAllSafras] = useState([]);
	const [allLotes, setAllLotes] = useState([]);
	const helpIdFinter = searchParams.get("id");
	const [inputQuantidade, setInputQuantidade] = useState("");
	const [errorMessageDateCad, setErrorMessageDateCad] = useState(false);
	const [errorMessageSelectType, setErrorMessageSelectType] = useState(false);
	const [errorMessageSelectLote, setErrorMessageSelectLote] = useState(false);
	const [errorMessageSelectSafra, setErrorMessageSelectSafra] = useState(false);
	const [errorMessageQuantidade, setErrorMessageQuantidade] = useState(false);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const keysForActiveSafra = columnForActiveSafra; //array de chaves
	const keysForActiveLote = columnForActiveLote; //array de chaves
	// const columnsForActiveLote = Object.values(columnForActiveLote); //array de valores
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [openSafra, setOpenSafra] = useState(false);
	const [openLote, setOpenLote] = useState(false);
	const [openLoteDialog, setOpenLoteDialog] = useState(false); //modal
	const [openSafraDialog, setOpenSafraDialog] = useState(false); //modal
	const [selectedDate, setSelectedDate] = useState("");
	const [unid, setUnid] = useState("");
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("");	
	const [selected, setSelected] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const lossService = new LossService();
	const cropSeasonService = new CropSeasonService();
	const harvestService = new HarvestService();
	
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClickSafra = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	const handleSelectAllClickLote = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const lossTypes = await lossService.getAllLossType();
		setAllLossesType(lossTypes);
		const cropSeasons = await cropSeasonService.get(propertyId, {limit: 100000});
		setAllSafras(getSafrasForProperty(cropSeasons));
		const harvestParams = {
			page: 1,
			limit: 100000,
			filterValue: encodeURI(
				JSON.stringify(
					{ 
						pendente: false, 
						previsao: false,
					}
				)
			),
			orderBy: JSON.stringify(
				{
					createdAt: "desc"
				}
			)
		};
		const harvests = await harvestService.getByProperty(propertyId,harvestParams);
		setAllLotes(getLotesForSafras(harvests));
		const lossId = searchParams.get("id");
		if (lossId !== null) {
			setId(lossId);
			const perda = await lossService.getById(lossId);
			setSelectLote(perda.loteId && perda.loteId !== "" ? perda.loteId : "0");
			setSelectSafra(
				perda.safraId && perda.safraId !== "" ? perda.safraId : "0"
			);
			setDescricao(perda.descricao);
			setInputQuantidade(perda.quantidade);

			// setSelectedDate(perda.data);
			setSelectedDate(formatDate(perda.data));
			
			setSelectType(perda.tipoPerdaId);
			if(perda.safraId !== null && perda.safraId !== undefined)
				getUnidMedida(perda.safraId, "safra");
			else
				getUnidMedida(perda.loteId, "lote");
		}
		setIsLoading(false);
	};

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date).toLocaleDateString("pt-BR"));
	};
	const getSafrasForProperty = (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			const farm = cropSeason.propriedadeCultivo;
			const cultivo = farm.cultivo;
			const subDiv = cropSeason?.subdivisao;
			const catSub = cropSeason?.categoriaSubdivisao;
			const plot = cropSeason.talhao;
			safras.push({
				id: cropSeason.id,
				safra: cropSeason,
				nome: `Talhão ${plot.nome}${
					subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
				} - ${captalizeFirstLetterEacheWord(cultivo.cultura)} ${captalizeFirstLetterEacheWord(cultivo.variedade ?? "")}`,
			});
		}
		return safras;
	};

	const getLotesForSafras = (harvests) => {
		const lotes = [];
		for (let i = 0; i < harvests.length; i++) {
			const harvest = harvests[i];

			lotes.push({
				id: harvest.id,
				date: harvest.dataColheita,
				harvest: harvest.codigo,
				cropSeason: getCropSeasonsName(harvest.loteSafras),
			});
		}
		return lotes;
	};

	const getCropSeasonsName = (cropSeasonList) => {
		let name = "";
		cropSeasonList.forEach((cropSeason) => {
			name += getCropSeasonName(cropSeason.safra) + ", ";
		});
		return name;
	};

	const getCropSeasonName = (cropSeason) => {
		const plot = cropSeason.talhao;
		let cropSeasonName = `Talhão ${plot.nome}`;
		if (cropSeason.subdivisao) {
			const subdivision = cropSeason.subdivisao;
			cropSeasonName += captalizeFirstLetterEacheWord(` - ${subdivision.categoriaSubdivisao} ${subdivision.complemento}`);
		}
		const propertyFarm = cropSeason.propriedadeCultivo;
		const farm = propertyFarm.cultivo;
		cropSeasonName += ` - ${captalizeFirstLetterEacheWord(farm.cultura)} ${captalizeFirstLetterEacheWord(farm.variedade ?? "")}`;
		cropSeasonName += ` - ${new Date(cropSeason.dataPlantio).toLocaleDateString("pt-BR")}`;
		return cropSeasonName;
	};

	const typeIsPosColheita = () => {
		const type = allLossesType.find((lossType) => lossType.id === selectType);
		return !type.preColheita;
	};

	const validationSave = async () => {
		if (selectType === "0") {
			setMessage("Selecione o tipo da Perda");
			setTypeMessage("error");
			setErrorMessageSelectType(true);
			setErrorMessageDateCad(false);
			setErrorMessageSelectLote(false);
			setErrorMessageSelectSafra(false);
			setErrorMessageQuantidade(false);
		} else if (typeIsPosColheita()) {
			setErrorMessageSelectType(false);
			if (
				selectedDate.length === 10 &&
				selectLote !== "0" &&
				inputQuantidade !== "0"
			) {
				await handleSave();
				setErrorMessageDateCad(false);
				setErrorMessageSelectLote(false);
				setErrorMessageSelectSafra(false);
				setErrorMessageQuantidade(false);
				setErrorMessageSelectType(false);
				setTypeMessage("success");
				setMessage("cadastro salvo!!!");
				return true;
			} else {
				let message = "Um ou mais campos vazios! - (";
				if (selectedDate.length !== 10) {
					message += "Data, ";
					setErrorMessageDateCad(true);
				} else {
					setErrorMessageDateCad(false);
				}

				if (selectSafra === "0") {
					message += "Selecionar Safra, ";
					setErrorMessageSelectSafra(true);
					setErrorMessageSelectLote(false);
				} else {
					setErrorMessageSelectSafra(false);
				}
				if (inputQuantidade === "") {
					message += "Quantidade, ";
					setErrorMessageQuantidade(true);
				} else {
					setErrorMessageQuantidade(false);
				}
				message += ")";
				setTypeMessage("error");
				setMessage(message);
				return false;
			}
		} else {
			setErrorMessageSelectType(false);
			if (
				selectedDate.length === 10 &&
				selectSafra !== "0" &&
				inputQuantidade !== "0"
			) {
				handleSave();
				setErrorMessageDateCad(false);
				setErrorMessageSelectLote(false);
				setErrorMessageSelectSafra(false);
				setErrorMessageQuantidade(false);
				setErrorMessageSelectType(false);
				setTypeMessage("success");
				setMessage("cadastro salvo!!!");
				return true;
			} else {
				let message = "Um ou mais campos vazios! - (";
				if (selectedDate.length !== 10) {
					message += "Data, ";
					setErrorMessageDateCad(true);
				} else {
					setErrorMessageDateCad(false);
				}
				if (selectLote === "0") {
					message += "Selecionar Lote, ";
					setErrorMessageSelectLote(true);
					setErrorMessageSelectSafra(false);
				} else {
					setErrorMessageSelectLote(false);
				}

				if (inputQuantidade === "") {
					message += "Quantidade, ";
					setErrorMessageQuantidade(true);
				} else {
					setErrorMessageQuantidade(false);
				}
				message += ")";
				setTypeMessage("error");
				setMessage(message);
				return false;
			}
		}
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};

	const handleSave = async () => {
		const propertyId = localStorage.getItem("propertyId");
		if (!id) {
			const lossToSave = {
				allotmentId: selectLote !== "0" ? selectLote : null,
				cropSeasonId: selectSafra !== "0" ? selectSafra : null,
				lossTypeId: selectType,
				description: document.getElementById("descricao").value,
				amount: Number(document.getElementById("quantidade").value),
				date: getFormatDate(selectedDate),
				propertyId,
			};
			await lossService.save(lossToSave);
		} else {
			const lossToUpdate = {
				id,
				allotmentId: selectLote !== "0" ? selectLote : null,
				cropSeasonId: selectSafra !== "0" ? selectSafra : null,
				lossTypeId: selectType,
				description: document.getElementById("descricao").value,
				amount: Number(document.getElementById("quantidade").value),
				date: getFormatDate(selectedDate),
				propertyId,
			};
			await lossService.update(lossToUpdate);
		}
	};

	const handleOpenSafra = () => {
		setOpenSafra(true); //abre o modal
	};
	const handleCloseSafra = () => {
		setOpenSafra(false); //fecha o modal
	};
	const handleCloseAddSafra = () => {
		setSafra(idRowSafra);
		setOpenSafra(false); //fecha o modal
		setOpenSafraDialog(false);
	};
	const handleOpenLote = () => {
		setOpenLote(true); //abre o modal
	};
	const handleCloseLote = () => {
		setOpenLote(false); //fecha o modal
	};
	const handleCloseAddLote = () => {
		setLote(idRowLote);
		setOpenLote(false); //fecha o modal
		setOpenLoteDialog(false);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	const handleOpenLoteDialog = (e) => {
		if (e.target.id !== undefined) setIdRowLote(e.target.id); //seta o id do elemento clicado para abrir o dialog

		setOpenLoteDialog(true); //abre o modal
	};

	const handleCloseLoteDialog = () => {
		setOpenLoteDialog(false); //fecha o modal
	};
	const handleOpenSafraDialog = (e) => {
		if (e.target.id !== undefined) setIdRowSafra(e.target.id); //seta o id do elemento clicado para abrir o dialog

		setOpenSafraDialog(true); //abre o modal
	};

	const handleCloseSafraDialog = () => {
		setOpenSafraDialog(false); //fecha o modal
	};

	const setSelectTypechange = (e) => {
		setSelectType(e);
		setErrorMessageDateCad(false);
		setErrorMessageSelectLote(false);
		setErrorMessageSelectSafra(false);
		setErrorMessageQuantidade(false);
		setErrorMessageSelectType(false);
	};
	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};
	const dataAllSafras = allSafras.map((item, index) => {
		return {
			id: item.id,
			nome: item.nome,
			data_plantio: new Date(item.safra.dataPlantio).toLocaleDateString("pt-BR"),
			index: index,
		};
	});

	const dataAllLotes = allLotes.map((item, index) => {
		return {
			id: item.id,
			harvest: item.harvest,
			date: formatDate(item.date),
			cropSeason: item.cropSeason,
			index: index,
		};
	});
	function setSafra(safraId) {
		setSelectSafra(safraId);
		getUnidMedida(safraId, "safra");
		setSelectLote("0");
	}

	function setLote(loteId) {
		setSelectSafra("0");
		setSelectLote(loteId);
		getUnidMedida(loteId, "lote");
	}
	async function getUnidMedida(e, type) {
		let unidade = null;
		if (type === "safra") {
			const safra = await cropSeasonService.getById(e);
			const propriedadeCultivo = safra.propriedadeCultivo;
			const embalagem = propriedadeCultivo.embalagens;
			unidade = embalagem[0].unidadeMedida;
		} else {
			const lote = await harvestService.getById(e);
			const embalagem = lote.embalagem;
			unidade = embalagem.unidadeMedida;
		}

		setUnid(unidade ? unidade.sigla : "");
	}
	const getFormatDateToOrder = (date) => {
		if(date!== undefined){
			let data = date.split("/");
			return `${data[2]}-${data[1]}-${data[0]}`;
		}
		return;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_plantio") {
			const dateA = new Date(getFormatDateToOrder(a[orderBy]));
			const dateB = new Date(getFormatDateToOrder(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "dataColheita") {
			const dateA = new Date(getFormatDateToOrder(a[orderBy]));
			const dateB = new Date(getFormatDateToOrder(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRowsLote = useMemo(
		() =>
			stableSort(dataAllLotes, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, dataAllLotes]
	);
	const visibleRowsSafra = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage, dataAllSafras]
	);
	const [descricao, setDescricao] = useState("");
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu
					open={["launch", "losses"]}
					help={helpIdFinter === null ? "lossesCad" : "lossesEdit"}
				/>
				<Box component="main" sx={{ p: 0, mt: 12, width: "100%" }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item sx={{ width: "100%" }}>
									<RegisterArea
										title={helpIdFinter === null ? "Registro de Perdas" : "Editar Perda"}
										href="/perdas"
										dataCriacao={true}
										dataAlteracao={true}
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11 ,position:"absolute",px:1,backgroundColor:"white",visibility:(selectType === "0" ?"hidden":"visible"),transition:"all 1s ease"}} >
														Tipo de perda
													</Typography>
													<div
														className={
															errorMessageSelectType ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageSelectType ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<select
															id="perda"
															value={selectType}
															onChange={(event) => setSelectTypechange(event.target.value)}
															className={styles.inputLossesCad} 
														>
															<option value={"0"}>
																{selectType !== "0" ? "---------------" : "Tipo de perda"}
															</option>
															{allLossesType.map((item, index) => {
																return (
																	<option value={item.id} key={index}>
																		{item.nome}
																	</option>
																);
															})}
														</select>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11 ,position:"absolute",px:1,backgroundColor:"white",visibility:(selectLote === "0" ?"hidden":"visible"),transition:"all 1s ease"}} >
														Lote
													</Typography>
													<div
														className={
															errorMessageSelectLote ? styles.errControl : styles.noErrControl
														}
													>
														<select
															id="lote"
															disabled
															value={selectLote}
															onChange={(e) => setLote(e.target.value)}
															className={styles.inputLossesCad} 
														>
															<option value={"0"}>
																{selectLote !== "0" ? "---------------" : "Selecionar Lote"}
															</option>
															{allLotes.map((item, index) => {
																return (
																	<option value={item.id} key={index}>
																		{item.harvest}
																	</option>
																);
															})}
														</select>

														<button
															onClick={handleOpenLote}
															disabled={
																!selectType ||
																selectType === "0" ||
																(selectType && selectType !== "0" && !typeIsPosColheita())
															}
															type="button"
															className={
																!selectType ||
																selectType === "0" ||
																(selectType && selectType !== "0" && !typeIsPosColheita())
																	? styles.ButtonCancelar
																	: styles.ButtonColher
															}
														>
															Selecionar lote
														</button>
														<p
															className={
																errorMessageSelectLote ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11 ,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedDate.length < 10 ?"hidden":"visible"),transition:"all 1s ease"}} >
														Data
													</Typography>
													<div
														className={
															errorMessageDateCad ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageDateCad ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<InputData placeholder="Data" onDateChange={handleDateChange} value={selectedDate} classN={ styles.inputLossesCad}  />
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11 ,position:"absolute",px:1,backgroundColor:"white",visibility:(selectSafra === "0" ?"hidden":"visible"),transition:"all 1s ease"}} >
														Safra
													</Typography>
													<div
														className={
															errorMessageSelectSafra ? styles.errControl : styles.noErrControl
														}
													>
														<select
															id="safra"
															onChange={(e) => setSafra(e.target.value)}
															value={selectSafra}
															disabled
															className={styles.inputLossesCad} 
														>
															<option value={"0"}>
																{selectSafra !== "0" ? "---------------" : "Selecionar safra"}
															</option>
															{allSafras.map((item, index) => {
																return (
																	<option value={item.id} key={index}>
																		{item.nome}
																	</option>
																);
															})}
														</select>
														<button
															onClick={handleOpenSafra}
															disabled={
																!selectType ||
																selectType === "0" ||
																(selectType && selectType !== "0" && typeIsPosColheita())
															}
															type="button"
															className={
																!selectType ||
																selectType === "0" ||
																(selectType && selectType !== "0" && typeIsPosColheita())
																	? styles.ButtonCancelar
																	: styles.ButtonColher
															}
														>
															Selecionar Safra
														</button>
														<p
															className={
																errorMessageSelectSafra ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
													</div>
												</Box>
											</Grid>
											<Grid item xs={10} sm={10} md={5}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11 ,position:"absolute",px:1,backgroundColor:"white",visibility:(inputQuantidade === "" ?"hidden":"visible"),transition:"all 1s ease"}} >
														Quantidade
													</Typography>
													<div
														className={
															errorMessageQuantidade ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageQuantidade ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<input
															type="number"
															placeholder="Quantidade"
															id="quantidade"
															value={inputQuantidade}
															onChange={(event) => setInputQuantidade(event.target.value)}
															className={styles.inputLossesCad} 
														/>
													</div>
												</Box>
											</Grid>
											<Grid
												item
												xs={2}
												sm={2}
												md={1}
												sx={{
													display: "flex",
													justifyContent: "flex-start",
													alignContent: "center",
													marginLeft: "20px",
													marginRight: "-20px",
												}}
											>
												<Item sx={stylesItem}>{unid}</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box	Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:2,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11 ,position:"absolute",px:1,backgroundColor:"white",visibility:(descricao === "" ?"hidden":"visible"),transition:"all 1s ease"}} >
														Descrição
													</Typography>
													<input type="text" placeholder="Descrição" id="descricao" value={descricao} onChange={e => setDescricao(e.target.value)} className={styles.inputLossesCad} />
												</Box>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={openLote}
					onClose={handleCloseLote}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<div className={styles.titleTabelaModal}>
									<h2>
										<b>Lotes</b>
									</h2>
									<HelpButtonInput
										title={"Tabela de Lotes"}
										text={"Clique em cima do item desejado para seleciona-lo."}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Paper sx={{ width: "100%", p: 1, m: 0 }}>
									<ThemeProvider theme={theme}>
										<TableContainer sx={{ maxHeight: "46vh" }}>
											<Table stickyHeader>
												<EnhancedTableHead
													className={styles.tableGenerator}
													numSelected={selected.length}
													order={order}
													orderBy={orderBy}
													onSelectAllClick={handleSelectAllClickLote}
													onRequestSort={handleRequestSort}
													rowCount={dataAllLotes.length}
													column={columnForActiveLote}
												/>
												<TableBody>
													{
														//cria uma linha por objeto
														visibleRowsLote
															.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
															.map((row, index) => {
																return (
																	<TableRow hover tabIndex={-1} key={index}>
																		{keysForActiveLote.map((column) => {
																			return (
																				<TableCell
																					key={column.id}
																					id={row.id}
																					onClick={handleOpenLoteDialog}
																				>
																					{row[column.id]}
																				</TableCell>
																			);
																		})}
																	</TableRow>
																);
															})
													}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
											component="div"
											colSpan={7}
											count={visibleRowsLote.length}
											rowsPerPage={rowsPerPage}
											page={page}
											labelRowsPerPage="Linhas por pagina"
											SelectProps={{
												inputProps: {
													"aria-label": "rows per page",
												},
												native: false,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</ThemeProvider>
								</Paper>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
											pt: 2,
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseLote}
													className={styles.ButtonCancelarModal}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
						<Dialog
							open={openLoteDialog}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleCloseLoteDialog}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogContent>
								<DialogContentText id="alert-dialog-slide-description">
									Deseja Selecionar esse item?
								</DialogContentText>
							</DialogContent>
							<DialogActions
								sx={{
									display: "flex",

									flexDirection: "row",
								}}
							>
								<button
									className={styles.ButtonCancelarModal}
									onClick={handleCloseLoteDialog}
								>
									Cancelar
								</button>

								<button
									className={styles.ButtonColherModal}
									onClick={handleCloseAddLote}
								>
									Selecionar
								</button>
							</DialogActions>
						</Dialog>
					</Box>
				</Modal>
				{/* safra  */}
				<Modal
					open={openSafra}
					onClose={handleCloseSafra}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<div className={styles.titleTabelaModal}>
									<h2>
										<b>Safra</b>
									</h2>
									<HelpButtonInput
										title={"Tabela de Safras"}
										text={"Clique em cima do item desejado para seleciona-lo."}
									/>
								</div>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={12} md={12}>
							<Paper
								sx={{
									width: "100%",
									p: 0,
									m: 0,
								}}
							>
								<ThemeProvider theme={theme}>
									<TableContainer sx={{ maxHeight: "46vh" }}>
										<Table stickyHeader>
											<EnhancedTableHead
												className={styles.tableGenerator}
												numSelected={selected.length}
												order={order}
												orderBy={orderBy}
												onSelectAllClick={handleSelectAllClickSafra}
												onRequestSort={handleRequestSort}
												rowCount={dataAllSafras.length}
												column={columnForActiveSafra}
											/>
											<TableBody>
												{
													//cria uma linha por objeto
													visibleRowsSafra.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
														return (
															<TableRow hover tabIndex={-1} key={index}>
																{keysForActiveSafra.map((column) => {
																	return (
																		<TableCell
																			key={column.id}
																			id={row.id}
																			onClick={handleOpenSafraDialog}
																		>
																			{row[column.id]}
																		</TableCell>
																	);
																})}
															</TableRow>
														);
													})
												}
											</TableBody>
										</Table>
									</TableContainer>
									<TablePagination
										rowsPerPageOptions={[7, 14, 21, { label: "Todos", value: Number.MAX_VALUE }]}
										component="div"
										colSpan={7}
										count={visibleRowsSafra.length}
										rowsPerPage={rowsPerPage}
										page={page}
										labelRowsPerPage="Linhas por pagina"
										SelectProps={{
											inputProps: {
												"aria-label": "rows per page",
											},
											native: false,
										}}
										onPageChange={handleChangePage}
										onRowsPerPageChange={handleChangeRowsPerPage}
										ActionsComponent={TablePaginationActions}
									/>
								</ThemeProvider>
							</Paper>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
											pt: 2,
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseSafra}
													className={styles.ButtonCancelarModal}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
						<Dialog
							open={openSafraDialog}
							TransitionComponent={Transition}
							keepMounted
							onClose={handleCloseSafraDialog}
							aria-describedby="alert-dialog-slide-description"
						>
							<DialogContent>
								<DialogContentText id="alert-dialog-slide-description">
									Deseja Selecionar esse item?
								</DialogContentText>
							</DialogContent>
							<DialogActions sx={{ display: "flex", flexWrap: "wrap" }}>
								<button
									className={styles.ButtonCancelarModal}
									onClick={handleCloseSafraDialog}
								>
									Cancelar
								</button>

								<button
									className={styles.ButtonColherModal}
									onClick={handleCloseAddSafra}
								>
									Selecionar
								</button>
							</DialogActions>
						</Dialog>
					</Box>
				</Modal>
			</div>
		);
}
export default LossesCad;
