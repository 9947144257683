import AddIcon from "@mui/icons-material/Add";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Modal, TableSortLabel, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import HelpButtonInput from "../../../components/HelpButtonInput";
import TopMenu from "../../../components/TopMenu";
import styles from "./HarvestCad.module.css";

//components
import RegisterArea from "../../../components/RegistrationArea";

import { useTheme } from "@emotion/react";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import { ptBR } from "@mui/material/locale";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import InputData from "../../../components/InputData";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../../utils/utils";

import { FarmService } from "../../../service/farm_service";
import { UserService } from "../../../service/user_service";
import { CropSeasonService } from "../../../service/crop_season_service";
import { HarvestService } from "../../../service/harvest_service";
import Loading from "../../../components/Loading";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);

const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
	m: 0,
	p: 0,
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
const styleAdd = {
	color: "#1A877F",
	margin: "0 0 0 5px",
};
const styleAddNoSelect = {
	color: "#DCDCDC",
	margin: "0 0 0 5px",
};
const columnForActive = [
	{
		id: "nome",
		label: "Nome",
	},
	{
		id: "data_plantio",
		label:"Data Plantio",
	},
	{
		id: "quantidade",
		label:"Quantidade",
	},
];
function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,columns } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead  className={styles.tableGenerator}>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function HarvestCad() {
	const [itemsSafra, setItemsSafra] = useState([]);
	const [allSafras, setAllSafras] = useState([]);
	const [allOperators, setAllOperators] = useState([]);
	const [allCultivos, setAllCultivos] = useState([]);
	const [allEmbalagens, setAllEmbalagens] = useState([]);
	const [searchParams] = useSearchParams();
	// const [selectedSafra, setSelectedSafra] = useState("0");
	const [selectedCultivo, setSelectedCultivo] = useState(null);

	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const helpIdFinter = searchParams.get("id");
	// const [inputQuantidade, setInputQuantidade] = useState([{}]);

	const [openSafra, setOpenSafra] = useState(false);
	let [finalized, setFinalized] = useState(false);
	let [quantidadeTotal, setQuantidadeTotal] = useState("");
	const [id, setId] = useState(null);
	const [errorMessageSelectedCultivo, setErrorMessageSelectedCultivo] =
		useState(false);
	const [errorMessageSelectedEmbalagem, setErrorMessageSelectedEmbalagem] =
		useState(false);
	const [errorMessageDateCad, setErrorMessageDateCad] = useState(false);
	const [errorMessageSelectOperador, setErrorMessageSelectOperador] =
		useState(false);
	const [errorMessageQuantidadeTotal, setErrorMessageQuantidadeTotal] =
		useState(false);
	const [errorMessageItemsSafra, setErrorMessageItemsSafra] = useState(false);
	const [selected, setSelected] = React.useState([]);
	const keysForActive = columnForActive; //array de chaves
	// const columnsForActive = Object.values(columnForActive); //array de valores
	const [page, setPage] = React.useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = React.useState(7); //paginação
	const [errorAddSafra, setErrorAddSafra] = React.useState(false);
	const [selectedDate, setSelectedDate] = useState("");
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("nome");
	const [isLoading, setIsLoading] = useState(true);

	const farmService = new FarmService();
	const userService = new UserService();
	const cropSeasonService = new CropSeasonService();
	const harvestService = new HarvestService();

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleSelectAllClicks = (event) => {
		if (event.target.checked) {
			const newSelected = dataAllSafras.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleDateChange = (date) => {
		setSelectedDate(new Date(date));
	};

	const formatDate = (dateToFormat) => {
		const date = new Date(dateToFormat);
		return (
			("0" + date.getUTCDate()).slice(-2) +
			"/" +
			("0" + (date.getUTCMonth() + 1)).slice(-2) +
			"/" +
			date.getUTCFullYear()
		);
	};

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const generalParams = {
			page: 1,
			limit: 100000,
		};
		const cropSeasonParams = {
			...generalParams,
			filterValue: encodeURI(JSON.stringify({finalizada: false}))
		};

		const farmsInProperty = await farmService.get(generalParams, propertyId);
		setAllCultivos(await getCultivos(farmsInProperty));
		const cropSeasonsInProperty = await cropSeasonService.get(propertyId, cropSeasonParams);
		setAllSafras(await getSafrasWithDetails(cropSeasonsInProperty));
		const usersInProperty = await userService.getByProperty(propertyId);
		setAllOperators(setOperators(usersInProperty));
		const harvestId = searchParams.get("id");

		if (harvestId !== null) {
			setId(harvestId);
			const lote = await harvestService.getById(harvestId);
			const cultivoInProperty = lote.propriedadeCultivo;
			const cultivo = cultivoInProperty.cultivo;
			const cultivoId = cultivo.id;
			setCultivo({
				firstLetter: `${cultivo.cultura} ${cultivo.variedade ?? ""}`[0].toUpperCase(),
				id: cultivoInProperty.id,
				idCultivo: cultivo.id,
				title: captalizeFirstLetterEacheWord(`${cultivo.cultura} ${cultivo.variedade ?? ""}`),
				index: cultivoId,
			});

			const embalagem = lote.embalagem;
			if (!lote.previsao) {
				const unidadeMedida = embalagem.unidadeMedida;
				setSelectedEmbalagem({
					firstLetter: embalagem.nome.toUpperCase(),
					title: `${embalagem.nome} (${embalagem.quantidade} ${unidadeMedida.sigla})`,
					id: embalagem.id,
				});
				setUnidadeMedidaSigla(unidadeMedida.sigla);
				handleDateChange(getDateWithoutTimezone(lote.dataColheita));
				const user = await userService.getById(lote.responsavelColheita);
				setSelectOperador({
					label: user.nome,
					id: lote.responsavelColheita,
					index: user.id,
				});
			} else {
				handleDateChange(getDateWithoutTimezone(lote.dataPrevista));
			}
			setQuantidadeTotal(lote.quantidade);
			setFinalized(!lote.pendente);
		}
		setIsLoading(false);
	};

	const getCultivos = async (farmsInProperty) => {
		const cultivations = [];
		for (let i = 0; i < farmsInProperty.length; i++) {
			const farm = farmsInProperty[i];
			const cultivation = farm.cultivo;
			cultivations.push({
				id: farm.id,
				idCultivo: cultivation.id,
				nome: captalizeFirstLetterEacheWord(`${cultivation.cultura} ${(cultivation.variedade ?? "")}`),
			});
		}
		return cultivations;
	};

	const getEmbalagens = async (farm) => {
		const embalagens = [];
		if (farm && farm !== undefined) {
			const embalagensForCultivo = farm.embalagens;
			for (let j = 0; j < embalagensForCultivo.length; j++) {
				let embalagem = embalagensForCultivo[j];
				const unidadeMedida = embalagem.unidadeMedida;
				embalagem.nome = `${embalagem.nome} (${embalagem.quantidade} ${unidadeMedida.sigla})`;
				embalagens.push(embalagem);
			}
		}
		setAllEmbalagens(embalagens);
	};

	const setOperators = (usersInProperty) => {
		const operators = [];
		if (usersInProperty && usersInProperty !== undefined) {
			for (let i = 0; i < usersInProperty.length; i++) {
				const user = usersInProperty[i].usuario;
				if (user.ativo) operators.push({ id: user.id, nome: user.nome });
			}
		}
		return operators;
	};

	const getSafrasWithDetails = async (cropSeasons) => {
		const safras = [];
		for (let j = 0; j < cropSeasons.length; j++) {
			const cropSeason = cropSeasons[j];
			if (!cropSeason.finalizada) {
				const farm = cropSeason.propriedadeCultivo;
				const subDiv = cropSeason.subdivisao;
				const cultivation = farm.cultivo;
				const catSub = subDiv?.categoriaSubdivisao;
				const plot = cropSeason.talhao;

				safras.push({
					id: cropSeason.id,
					idCultivo: farm.id,
					safra: cropSeason,
					nome: `Talhão ${plot.nome} ${
						subDiv !== null ? captalizeFirstLetterEacheWord(catSub) + " " + subDiv?.complemento : ""
					}- ${captalizeFirstLetterEacheWord(cultivation.cultura)} ${captalizeFirstLetterEacheWord(cultivation.variedade ?? "")}`,
				});
			}
		}
		return safras;
	};

	const [selectOperador, setSelectOperador] = useState(null);
	const [selectedEmbalagem, setSelectedEmbalagem] = useState(null);
	const [unidadeMedidaSigla, setUnidadeMedidaSigla] = useState("");

	const handleAddSafra = async () => {
		let newItemsSafra = [...itemsSafra];
		for (let j = 0; j < selected.length; j++) {
			let safra = "";
			let quantidade = "";
			for (let i = 0; i < allSafras.length; i++) {
				if (dataAllSafras[i].id === selected[j]) {
					quantidade = document.getElementById(
						dataAllSafras[i].id + dataAllSafras[i].nome
					).value;
					safra = dataAllSafras[i];
					newItemsSafra = {
						itemId: safra.id,
						itemName: safra.nome,
						itemQuantity: quantidade,
					};
					itemsSafra.push(newItemsSafra);
					break;
				}
			}
		}
		return;
	};

	const handleRemoveSafra = (index) => {
		const newItems = itemsSafra.filter((item, itemIndex) => itemIndex !== index);
		setItemsSafra(newItems);
	};

	const handleOpenSafra = () => {
		setSelected([]);
		setOpenSafra(true); //abre o modal
	};
	const handleCloseSafra = () => {
		setSelected([]);
		setOpenSafra(false); //fecha o modal
	};

	const handleCloseAddSafra = () => {
		let Aux = 0;
		let AuxError = 0;
		for (let j = 0; j < selected.length; j++) {
			let quantidade = "";
			for (let i = 0; i < dataAllSafras.length; i++) {
				if (dataAllSafras[i].id === selected[j]) {
					quantidade = document.getElementById(
						dataAllSafras[i].id + dataAllSafras[i].nome
					).value;
					if (quantidade <= 0) {
						let getElementById = document.getElementById(
							dataAllSafras[i].id + dataAllSafras[i].nome + "controlError"
						);
						getElementById.className = styles.errControl;
						AuxError += 1;
					} else {
						let getElementById = document.getElementById(
							dataAllSafras[i].id + dataAllSafras[i].nome + "controlError"
						);
						getElementById.className = "";

						Aux += 1;
					}
				}
			}
		}
		if (Aux > 0 && AuxError <= 0) {
			handleAddSafra(); //adiciona o item no array
			setOpenSafra(false); //fecha o modal
			setErrorAddSafra(false);
		} else {
			if (selected.length === 0) setErrorAddSafra(true);
			else setErrorAddSafra(false);
		}
	};

	const setCultivo = async (farmObject) => {
		if (farmObject !== selectedCultivo) {
			const propertyId = localStorage.getItem("propertyId");
			const generalParams = {
				page: 1,
				limit: 100000,
			};
			const cropSeasonParams = {
				...generalParams,
				filterValue: encodeURI(JSON.stringify({finalizada: false, propriedadeCultivoId: farmObject.id}))
			};
			const cropSeasonsInProperty = await cropSeasonService.get(propertyId, cropSeasonParams);
			setAllSafras(await getSafrasWithDetails(cropSeasonsInProperty));
			setSelectedCultivo(farmObject);
			const farm = await farmService.getById(farmObject.id);
			getEmbalagens(farm);
		}

		if (farmObject?.idCultivo === null) {
			setSelectedEmbalagem(null);
			setUnidadeMedidaSigla("");
			setItemsSafra([]);
		}
	};

	const validationSave = async () => {
		if (finalized === false) {
			if (
				selectedCultivo &&
				selectedEmbalagem &&
				selectedDate &&
				selectOperador &&
				quantidadeTotal > 0
			) {
				await handleSave();
				setTypeMessage("success");
				setMessage("cadastro salvo!!!");
				return true;
			} else {
				let message = "Um ou mais campos vazios! - (";
				if (selectedDate.length !== 10) {
					message += "Data de colheita, ";
					setErrorMessageDateCad(true);
				} else {
					setErrorMessageDateCad(false);
				}
				if (selectedCultivo === null) {
					message += "Selecionar Cultura/Varieade, ";
					setErrorMessageSelectedCultivo(true);
				} else {
					setErrorMessageSelectedCultivo(false);
				}
				if (selectedEmbalagem === null) {
					message += "Selecionar Embalagem, ";
					setErrorMessageSelectedEmbalagem(true);
				} else {
					setErrorMessageSelectedEmbalagem(false);
				}
				if (selectOperador === null) {
					message += "Selecionar operador, ";
					setErrorMessageSelectOperador(true);
				} else {
					setErrorMessageSelectOperador(false);
				}
				if (quantidadeTotal === "0" || quantidadeTotal === "") {
					message += "Quantidade";
					setErrorMessageItemsSafra(false);
					setErrorMessageQuantidadeTotal(true);
				} else {
					setErrorMessageQuantidadeTotal(false);
				}
				message += ")";
				setTypeMessage("error");
				setMessage(message);
				return false;
			}
		} else {
			if (
				selectedCultivo &&
				selectedEmbalagem &&
				selectedDate &&
				selectOperador &&
				(itemsSafra.length > 0 || id !== null)
			) {
				handleSave();
				setTypeMessage("success");
				setMessage("cadastro salvo!!!");
				return true;
			} else {
				let message = "Um ou mais campos vazios! - (";
				if (selectedDate.length !== 10) {
					message += "Data de colheita, ";
					setErrorMessageDateCad(true);
				} else {
					setErrorMessageDateCad(false);
				}
				if (selectedCultivo === null) {
					message += "Selecionar Cultura/Varieade, ";
					setErrorMessageSelectedCultivo(true);
				} else {
					setErrorMessageSelectedCultivo(false);
				}
				if (selectedEmbalagem === null) {
					message += "Selecionar Embalagem, ";
					setErrorMessageSelectedEmbalagem(true);
				} else {
					setErrorMessageSelectedEmbalagem(false);
				}
				if (selectOperador === null) {
					message += "Selecionar operador, ";
					setErrorMessageSelectOperador(true);
				} else {
					setErrorMessageSelectOperador(false);
				}
				if (itemsSafra.length === 0 && id === null) {
					message += "Adicionar safras";
					setErrorMessageQuantidadeTotal(false);
					setErrorMessageItemsSafra(true);
				} else {
					setErrorMessageItemsSafra(false);
				}
				message += ")";
				setTypeMessage("error");
				setMessage(message);
				return false;
			}
		}
	};

	const handleSave = async () => {
		const userId = localStorage.getItem("user");

		const cropSeasons = [];
		itemsSafra.forEach(safra => {
			cropSeasons.push({
				cropSeasonId: safra.itemId,
				amount: Number(safra.itemQuantity),
			});
		});
		
		if (!id) {
			let quantityToHarvest = !finalized && quantidadeTotal && quantidadeTotal !== ""
				? Number(quantidadeTotal)
				: 0;

			if(finalized) {
				itemsSafra.forEach(safra => {
					quantityToHarvest += safra.itemQuantity;
				});
			}

			const harvestToSave = {
				farmId: selectedCultivo.id,
				packagingId: selectedEmbalagem.id,
				operator: selectOperador && selectOperador.id !== null ? selectOperador.id : userId,
				totalAmount: quantityToHarvest,
				harvestDate: selectedDate,
				forecast: false, 
				pending: !finalized,
				cropSeasons
			};
			await harvestService.save(harvestToSave);
		} else {
			const harvestToSave = {
				id,
				farmId: selectedCultivo.id,
				packagingId: selectedEmbalagem.id,
				operator: selectOperador && selectOperador.id !== null ? selectOperador.id : userId,
				totalAmount: !finalized && quantidadeTotal && quantidadeTotal !== ""
					? Number(quantidadeTotal)
					: null,
				harvestDate: new Date(selectedDate),
				forecast: false, 
				pending: !finalized,
				cropSeasons
			};
			await harvestService.update(harvestToSave);
		}
	};

	const dataAllCultivos = allCultivos.map((item, index) => {
		return {
			title: item.nome,
			id: item.id,
			idCultivo: item.idCultivo,
			index: index,
		};
	});
	const optionsAllCultivos = dataAllCultivos.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	const dataAllEmbalagens = allEmbalagens.map((item, index) => {
		return {
			title: item.nome,
			id: item.id,
			index: index,
		};
	});
	const optionsAllEmbalagens = dataAllEmbalagens.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	const dataAllOperators = allOperators.map((item, index) => {
		return {
			label: item.nome,
			id: item.id,
			index: index,
		};
	});
	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const dataAllSafras = allSafras.map((item, index) => {
		return {
			id: item.id,
			nome: item.nome,
			data_plantio: formatDate(item.safra.dataPlantio),
			quantidade: (
				<div id={item.id + item.nome + "controlError"}>
					<input
						id={item.id + item.nome}
						type={"number"}
						placeholder={"quantidade"}
					/>
				</div>
			),
			index: index,
		};
	});

	const handleChangePage = (event, newPage) => {
		// handleAddSafra();
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};
	const setFinalizedChange = (e) => {
		setFinalized(e);
		if (finalized) {
			setItemsSafra([]);
			setErrorMessageItemsSafra(false);
		} else {
			setErrorMessageQuantidadeTotal(false);
		}
	};

	const changeSelectedEmbalagem = async (newValue) => {
		setSelectedEmbalagem(newValue);
		const embalagem = allEmbalagens[newValue.index];
		const unidadeMedida = await embalagem.unidadeMedida;
		setUnidadeMedidaSigla(unidadeMedida.sigla);
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_plantio") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRows = useMemo(
		() =>
			stableSort(dataAllSafras, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataAllSafras]
	);
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu
					open={["launch", "harvest"]}
					help={helpIdFinter === null ? "harvestCad" : "harvestEdit"}
				/>
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex", width: "100%" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title={
											helpIdFinter === null ? "Lançamento de Colheita" : "Editar Colheita"
										}
										href="/colheita"
										dataCriacao={true}
										dataAlteracao={true}
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
											<Grid item xs={12} sm={12} md={4}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:3,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedCultivo === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Selecionar Cultura/Variedade
													</Typography>
													<div
														className={
															errorMessageSelectedCultivo
																? styles.errControl
																: styles.noErrControl
														}
													>
														<p
															className={
																errorMessageSelectedCultivo ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<ThemeProvider theme={theme}>
															<Autocomplete
																options={optionsAllCultivos.sort(
																	(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
																)}
																groupBy={(option) => option.firstLetter}
																getOptionLabel={(option) => option.title}
																sx={{ width: "100%", border: "none" }}
																value={selectedCultivo === undefined ? "" : selectedCultivo}
																onChange={(event, newValue) => {
																	setCultivo(newValue);
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Selecionar Cultura/Variedade"
																			className={styles.inputHarvestCad}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={4}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:3,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedEmbalagem === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Selecionar Embalagem
													</Typography>
													<div
														className={
															errorMessageSelectedEmbalagem
																? styles.errControl
																: styles.noErrControl
														}
													>
														<p
															className={
																errorMessageSelectedEmbalagem ? styles.errText : styles.noErrText
															}
														>
															*
														</p>

														<ThemeProvider theme={theme}>
															<Autocomplete
																options={optionsAllEmbalagens.sort(
																	(a, b) => -b.firstLetter.localeCompare(a.firstLetter)
																)}
																groupBy={(option) => option.firstLetter}
																getOptionLabel={(option) => option.title}
																sx={{ width: "100%", border: "none" }}
																value={selectedEmbalagem === undefined ? "" : selectedEmbalagem}
																onChange={(event, newValue) => changeSelectedEmbalagem(newValue)}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Selecionar Embalagem"
																			className={styles.inputHarvestCad}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={4}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:3,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectedDate.length < 10?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Data
													</Typography>
													<div
														className={
															errorMessageDateCad ? styles.errControl : styles.noErrControl
														}
													>
														<p
															className={
																errorMessageDateCad ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<InputData placeholder="Data" value={selectedDate ? new Date(selectedDate).toLocaleDateString("pt-BR") : null} onDateChange={handleDateChange} classN={styles.inputHarvestCad}/>
													</div>
												</Box>
											</Grid>
											<Grid item xs={12} sm={12} md={6}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:3,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(selectOperador === null?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Operador
													</Typography>
													<div
														className={
															errorMessageSelectOperador
																? styles.errControl
																: styles.noErrControl
														}
													>
														<p
															className={
																errorMessageSelectOperador ? styles.errText : styles.noErrText
															}
														>
															*
														</p>

														<ThemeProvider theme={theme}>
															<Autocomplete
																options={dataAllOperators}
																sx={{ width: "100%", border: "none" }}
																value={selectOperador === undefined ? "" : selectOperador}
																onChange={(event, newValue) => {
																	setSelectOperador(newValue);
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Selecionar operador"
																			className={styles.inputHarvestCad}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</div>
												</Box>
											</Grid>
											<Grid item xs={10} sm={10} md={5}>
												<Box sx={{
													border: "1px solid rgba(0,0,0,0.3)",
													borderRadius: "8px",
													width: "100%",
													display: "flex",
													justifyContent: "flex-start",
													flexDirection: "column",
													alignItems: "flex-start",
													px: 1,
													pr:3,
													position: "relative",
													m:0
												}}>
													<Typography variant="caption" display="block" gutterBottom sx={{top:-11,position:"absolute",px:1,backgroundColor:"white",visibility:(quantidadeTotal === ""?"hidden":"visible"),transition:"all 0.5s ease"}} >
														Quantidade
													</Typography>
													<div
														className={
															errorMessageQuantidadeTotal
																? styles.errControl
																: styles.noErrControl
														}
													>
														<p
															className={
																errorMessageQuantidadeTotal ? styles.errText : styles.noErrText
															}
														>
															*
														</p>
														<input
															type="number"
															placeholder="Quantidade"
															disabled={finalized}
															value={
																finalized === false
																	? quantidadeTotal > 0
																		? quantidadeTotal
																		: ""
																	: (quantidadeTotal = "")
															}
															onChange={(e) => setQuantidadeTotal(e.target.value)}
															className={styles.inputHarvestCad}
														/>
													</div>
												</Box>
											</Grid>
											<Grid
												item
												xs={2}
												sm={2}
												md={1}
												sx={{
													display: "flex",
													justifyContent: "flex-start",
													alignContent: "center",
													marginLeft: "20px",
													marginRight: "-20px",
												}}
											>
												<Item sx={stylesItem}>{unidadeMedidaSigla}</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.checkboxContainer}>
														<input
															className={styles.inputCheckbox}
															type="checkbox"
															id="modalCheckbox"
															name="modalCheckbox"
															value="colheita"
															checked={finalized}
															onClick={() => setFinalizedChange(!finalized)}
														/>
														<label htmlFor="modalCheckbox">Colheita realizada</label>
													</div>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<a
															onClick={finalized ? handleOpenSafra : () => {}}
															className={
																finalized ? styles.addBtn : styles.addBtnNoCursor
															}
														>
															Adicionar safras
															<AddIcon style={finalized ? styleAdd : styleAddNoSelect} />
														</a>
													</div>
													<p
														className={
															errorMessageItemsSafra ? styles.errText : styles.noErrText
														}
													>
														Adicione no minimo <b>UMA</b> safra *
													</p>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={{ minHeight: "45px", minWidth: "50vw" }}>
													<Grid
														container
														rowSpacing={1}
														columnSpacing={{ xs: 1, sm: 2, md: 3 }}
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "start",
															width: "100%",
														}}
													>
														{itemsSafra.map((item, index) => (
															<Grid item key={index}>
																<Item sx={stylesItem}>
																	<div className={styles.itemNameCuture}>
																		<button
																			className={styles.itemButtonCuture}
																			type="button"
																			id={item.itemId}
																			onClick={() => handleRemoveSafra(index)}
																		>
																			X
																		</button>
																		{item.itemName + " - " + item.itemQuantity}
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				{/* modalContent */}
				<Modal
					open={openSafra}
					onClose={handleCloseSafra}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<div className={styles.titleTabelaModal}>
									<h2>
										<b>Adicionar Safras</b>
									</h2>
									<HelpButtonInput
										title={"Tabela de Safras"}
										text={"Selecione a Safra desejada e adicione uma valor"}
									/>
									{errorAddSafra ? (
										<p className={styles.errText}>Adicione pelo menos uma Safra</p>
									) : (
										""
									)}
								</div>
								<Paper sx={{ width: "100%", p: 0, m: 0 }}>
									<ThemeProvider theme={theme}>
										<TableContainer sx={{ maxHeight: "60vh" }}>
											<Table stickyHeader>
												<EnhancedTableHead
													numSelected={selected.length}
													order={order}
													orderBy={orderBy}
													onSelectAllClick={handleSelectAllClicks}
													onRequestSort={handleRequestSort}
													rowCount={dataAllSafras.length}
													columns={columnForActive}
												/>
												<TableBody>
													{
														//cria uma linha por objeto
														visibleRows
															.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
															.map((row, index) => {
																const isItemSelected = isSelected(row.id);
																return (
																	<TableRow
																		hover
																		role="checkbox"
																		aria-checked={isItemSelected}
																		tabIndex={-1}
																		key={index}
																	>
																		<TableCell padding="checkbox">
																			<Checkbox
																				color="success"
																				checked={isItemSelected}
																				id={row.id}
																				onClick={(event) => handleClick(event, row.id)}
																			/>
																		</TableCell>
																		{keysForActive.map((column) => {
																			return (
																				<TableCell
																					key={column.id}
																					id={row.id}
																					onClick={(event) =>
																						column.id === "quantidade" ? "" : handleClick(event, row.id)
																					}
																				>
																					{row[column.id]}
																				</TableCell>
																			);
																		})}	
																	</TableRow>
																);
															})
													}
												</TableBody>
											</Table>
										</TableContainer>
										<TablePagination
											rowsPerPageOptions={[
												7,
												14,
												21,
												{ label: "Todos", value: Number.MAX_VALUE },
											]}
											component="div"
											colSpan={7}
											count={visibleRows.length}
											rowsPerPage={rowsPerPage}
											page={page}
											labelRowsPerPage="Linhas por pagina"
											SelectProps={{
												inputProps: {
													"aria-label": "rows per page",
												},
												native: false,
											}}
											onPageChange={handleChangePage}
											onRowsPerPageChange={handleChangeRowsPerPage}
											ActionsComponent={TablePaginationActions}
										/>
									</ThemeProvider>
								</Paper>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
											pt: 2,
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleCloseSafra}
													className={styles.registrationButtonCancelar}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													className={styles.registrationButtoncadastrar}
													type="button"
													onClick={handleCloseAddSafra}
												>
													Cadastrar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}
export default HarvestCad;
