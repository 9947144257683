import React, { useEffect, useMemo, useState } from "react";
import InputSearch from "../../components/SearchHeader/inputSeach";
import TopMenu from "../../components/TopMenu";

// import styles modules
/* import "./style.css"; */
import styles from "./Safra.module.css";

//import material-ui
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { Box, Modal, Tab, TableSortLabel, Tabs, ThemeProvider, Typography, createTheme, useTheme } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as locales from "@mui/material/locale";
import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";
import { GenericButtonGreen } from "../../components/Buttons";
import ButtonTable from "../../components/Buttons/buttonTable";

import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";

//import components
import InputData from "../../components/InputData";
import Search from "../../components/SearchHeader";
import { captalizeFirstLetterEacheWord, getDateWithoutTimezone } from "../../utils/utils";
import { CropSeasonService } from "../../service/crop_season_service";
import Loading from "../../components/Loading";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: "100%",
	transform: "translate(-50%, -50%)",
};
//data exmplo
const columnForActive = [
	{
		id: "nome",
		label: "Nome",
	},
	{
		id: "localizacao",
		label: "Localização",
	},
	{
		id: "dataPlantio",
		label: "Início de Safra",
	},
];

const columnForFinalized = [
	{
		id: "nome",
		label: "Nome",
	},
	{
		id: "localizacao",
		label: "Localização",
	},
	{
		id: "dataPlantio",
		label: "Início de Safra",
	},
	{
		id: "dataFinalizacao",
		label: "Final de Safra",
	},
];

function TablePaginationActions(props) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange } = props;

	const handleFirstPageButtonClick = (event) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box sx={{ flexShrink: 0, ml: 2.5 }}>
			<IconButton
				onClick={handleFirstPageButtonClick}
				disabled={page === 0}
				aria-label="first page"
			>
				{theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
			</IconButton>
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
			>
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			<IconButton
				onClick={handleLastPageButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="last page"
			>
				{theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
			</IconButton>
		</Box>
	);
}

TablePaginationActions.propTypes = {
	count: PropTypes.number.isRequired,
	onPageChange: PropTypes.func.isRequired,
	page: PropTypes.number.isRequired,
	rowsPerPage: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
	const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,columns } =
    props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className={styles.tableGenerator}>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						color="primary"
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all desserts",
						}}
					/>
				</TableCell>
				{columns.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === "desc" ? "sorted descending" : "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				<TableCell></TableCell>
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};
const stylesItem = {
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
};
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}
function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 0, width: "auto" }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
};

function CropSeason() {
	const [search, setSearch] = useState("");
	const [openModal, setOpenModal] = useState(false);
	const keysForActive = columnForActive; //array de chaves
	const keysForFinalized = columnForFinalized; //array de chaves
	const [finalizedDate, setFinalizedDate] = useState(null);
	const [value, setValue] = React.useState(0);
	const [message, setMessage] = useState("");
	const [error, setError] = useState(false);
	const [page, setPage] = useState(0); //paginação
	const [rowsPerPage, setRowsPerPage] = useState(7); //paginação
	const [selected, setSelected] = useState([]);
	const [allActiveSafras, setAllActiveSafras] = useState([]);
	const [allFinalizedSafras, setAllFinalizedSafras] = useState([]);
	const [order, setOrder] = useState("asc");
	const [orderBy, setOrderBy] = useState("nome");
	const [isLoading, setIsLoading] = useState(true);

	const cropSeasonService = new CropSeasonService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const propertyId = localStorage.getItem("propertyId");
		const cropSeasonParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					createdAt: "desc"
				}
			])
		};

		let cropSeasonInProperty = await cropSeasonService.get(propertyId, cropSeasonParams);

		cropSeasonInProperty = cropSeasonInProperty.map((cropSeason) => {
			let localization =  `Talhão ${cropSeason.talhao.nome}`;

			if(cropSeason.subdivisao)
				localization +=  ` - ${cropSeason.subdivisao.categoriaSubdivisao} ${cropSeason.subdivisao.complemento}`;

			return {
				...cropSeason,
				nome: captalizeFirstLetterEacheWord(`${cropSeason.propriedadeCultivo.cultivo.cultura} ${cropSeason.propriedadeCultivo.cultivo.variedade ?? ""}`),
				localizacao: localization,
				dataPlantio: cropSeason.dataPlantio ? getDateWithoutTimezone(cropSeason.dataPlantio).toLocaleDateString("pt-BR") : "",
				dataFinalizacao: cropSeason.dataFinalizacao ? getDateWithoutTimezone(cropSeason.dataFinalizacao).toLocaleDateString("pt-BR") : ""
			};
		});

		const activeCropSeasons = cropSeasonInProperty.filter((cropSeason) => !cropSeason.finalizada);
		const finalizedCropSeasons = cropSeasonInProperty.filter((cropSeason) => cropSeason.finalizada);
		setAllActiveSafras(activeCropSeasons);
		setAllFinalizedSafras(finalizedCropSeasons);
		setIsLoading(false);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};
	const handleSelectAllClickAtivo = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilterAtivos.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleSelectAllClickFinalized = (event) => {
		if (event.target.checked) {
			const newSelected = dataFilterFinalizados.map((n) => n.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};
	
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage); //paginação
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value); //paginação
		setPage(0);
	};

	const dataFilterFinalizados = allFinalizedSafras.filter(
		(item) => item.nome.toLowerCase().includes(search.toLowerCase()) //filtra os dados pelo nome do cliente
	);

	const dataFilterAtivos = allActiveSafras.filter(
		(item) => item.nome.toLowerCase().includes(search.toLowerCase()) //filtra os dados pelo nome do cliente
	);
	function handleClickSearch(e) {
		setSearch(e.target.value);
	}

	const handleClick = (e, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};
	const isSelected = (name) => selected.indexOf(name) !== -1;

	const theme = useTheme();

	const themeWithLocale = React.useMemo(
		() => createTheme(theme, locales["ptBR"]),
		["ptBR", theme]
	);
	const handleDelete = async (id) => {
		await cropSeasonService.changeVisibility(id);
		await initData();
	};

	const handleCloseModal = async () => {
		setMessage("");
		setError(false);
		setOpenModal(false); //fecha o modal
	};

	const handleFinalize = async () => {
		if (finalizedDate) {
			for (let i = 0; i < selected.length; i++) {
				const cropSeasonId = selected[i];
				await cropSeasonService.finish(cropSeasonId, {finishDate: finalizedDate});
			}
			setOpenModal(false); //fecha o modal
			await initData();
		} else {
			setMessage("Campo de data vazio");
			setError(!error);
		}
	};
	const handleOpenItemName = () => {
		if (selected.length > 0) setOpenModal(true); //abre o modal
	};
	const getFormatDate = (date) => {
		let data = date.split("/");
		return `${data[2]}-${data[1]}-${data[0]}`;
	};
	function descendingComparator(a, b, orderBy) {
		if (orderBy === "data_finalizacao") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}
		if (orderBy === "data_inicio") {
			const dateA = new Date(getFormatDate(a[orderBy]));
			const dateB = new Date(getFormatDate(b[orderBy]));
			if (dateB < dateA) return -1;
			if (dateB > dateA) return 1;
			return 0;
		}

		if (b[orderBy] < a[orderBy]) 
			return -1;
  
		if (b[orderBy] > a[orderBy]) 
			return 1;
  
		return 0;
	}
	function stableSort(array, comparator) {
		const stabilizedThis = array.map((el, index) => [el, index]);
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) 
				return order;
    
			return a[1] - b[1];
		});
		return stabilizedThis.map((el) => el[0]);
	}
	function getComparator(order, orderBy) {
		return order === "desc"
			? (a, b) => descendingComparator(a, b, orderBy)
			: (a, b) => -descendingComparator(a, b, orderBy);
	}
	const visibleRowsAtivo = useMemo(
		() =>
			stableSort(dataFilterAtivos, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilterAtivos]
	);
	const visibleRowsFinalizado = useMemo(
		() =>
			stableSort(dataFilterFinalizados, getComparator(order, orderBy)),
		[order, orderBy, page, rowsPerPage,dataFilterFinalizados]
	);
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["launch", "safra"]} help="safra" />
				<Box component="main" sx={{ width: "100vw", p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid container rowSpacing={1} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
									}}
								>
									<Search
										title="Safra"
										href="/safra/cadastroSafra"
										textButton="Nova Safra"
									>
										<InputSearch func={handleClickSearch} />
									</Search>
								</Item>
							</Grid>
							<Grid item xs={12}>
								<Item
									sx={{
										bgcolor: "rgba(0, 0, 0, 0)",
										border: "none",
										boxShadow: "none",
										p: 0,
										m: 0,
									}}
								>
									<ThemeProvider theme={themeWithLocale}>
										<Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<Tabs
														value={value}
														onChange={handleChange}
														textColor="white"

														// className="tabs"
													>
														<Tab
															className={
																value === 0 ? styles.tab + " " + styles.focuz : styles.tab
															}
															label="Ativo"
															{...a11yProps(0)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "30%",
															}}
														/>
														<Tab
															className={
																value === 1 ? styles.tab + " " + styles.focuz : styles.tab
															}
															label="Finalizado "
															{...a11yProps(1)}
															sx={{
																fontSize: { xs: 9, sm: 10, md: 14 },
																width: "30%",
															}}
														/>
													</Tabs>
												</Item>
											</Grid>
											<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<TabPanel className={styles.tabPanel} value={value} index={0}>
														<Paper sx={{ width: "100%", p: 0, m: 0 }}>
															<ThemeProvider theme={themeWithLocale}>
																<GenericButtonGreen
																	text="Finalizar safras"
																	funcClick={handleOpenItemName}
																	className={styles.btnSafra}
																	icon={<CheckCircleIcon />} //abrir modal com data
																/>
																<TableContainer>
																	<Table stickyHeader>
																		<EnhancedTableHead
																			numSelected={selected.length}
																			order={order}
																			orderBy={orderBy}
																			onSelectAllClick={handleSelectAllClickAtivo}
																			onRequestSort={handleRequestSort}
																			rowCount={dataFilterAtivos.length}
																			columns={columnForActive}
																		/>
																		
																		<TableBody>
																			{
																				//cria uma linha por objeto
																				visibleRowsAtivo
																					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
																					.map((row, index) => {
																						const isItemSelected = isSelected(row.id);
																						return (
																							<TableRow
																								hover
																								onClick={(event) => handleClick(event, row.id)}
																								role="checkbox"
																								aria-checked={isItemSelected}
																								tabIndex={-1}
																								key={index}
																							>
																								<TableCell padding="checkbox">
																									<Checkbox color="success" checked={isItemSelected} />
																								</TableCell>
																								{keysForActive.map((column) => {
																									return (
																										<TableCell key={column}>{row[column.id]}</TableCell>
																									);
																								})}

																								<TableCell>
																									<ButtonTable
																										className={styles.btn}
																										rowId={row.id}
																										editHref={`/safra/cadastroSafra?id=${row.id}`}
																										deletFunc={handleDelete}
																										deleteMessage="Deseja deletar? (Todos os pedidos, manejos, perdas e ocorrências relacionados serão excluídos)"
																									/>
																								</TableCell>
																							</TableRow>
																						);
																					})
																			}
																		</TableBody>
																	</Table>
																</TableContainer>
																<TablePagination
																	rowsPerPageOptions={[
																		7,
																		14,
																		21,
																		{ label: "Todos", value: Number.MAX_VALUE },
																	]}
																	component="div"
																	colSpan={7}
																	count={visibleRowsAtivo.length}
																	rowsPerPage={rowsPerPage}
																	page={page}
																	labelRowsPerPage="Linhas por pagina"
																	SelectProps={{
																		inputProps: {
																			"aria-label": "rows per page",
																		},
																		native: false,
																	}}
																	onPageChange={handleChangePage}
																	onRowsPerPageChange={handleChangeRowsPerPage}
																	ActionsComponent={TablePaginationActions}
																/>
															</ThemeProvider>
														</Paper>
													</TabPanel>
													<TabPanel className={styles.tabPanel} value={value} index={1}>
														<Paper sx={{ width: "100%", p: 0, m: 0 }}>
															<ThemeProvider theme={themeWithLocale}>
																<TableContainer>
																	<Table stickyHeader>
																	
																		<EnhancedTableHead
																			numSelected={selected.length}
																			order={order}
																			orderBy={orderBy}
																			onSelectAllClick={handleSelectAllClickFinalized}
																			onRequestSort={handleRequestSort}
																			rowCount={dataFilterFinalizados.length}
																			columns={columnForFinalized}
																		/>
																		
																		<TableBody>
																			{
																				//cria uma linha por objeto
																				visibleRowsFinalizado
																					.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
																					.map((row) => {
																						const isItemSelected = isSelected(row.id);
																						return (
																							<TableRow
																								hover
																								onClick={(event) => handleClick(event, row.id)}
																								role="checkbox"
																								aria-checked={isItemSelected}
																								tabIndex={-1}
																								key={row.id}
																							>
																								<TableCell padding="checkbox">
																									<Checkbox color="success" checked={isItemSelected} />
																								</TableCell>
																								{keysForFinalized.map((column) => {
																									return (
																										<TableCell key={column.id}>{row[column.id]}</TableCell>
																									);
																								})}

																								<TableCell>
																									<ButtonTable
																										className={styles.btn}
																										rowId={row.id}
																										editHref={`/safra/cadastroSafra?id=${row.id}`}
																										deletFunc={handleDelete}
																									/>
																								</TableCell>
																							</TableRow>
																						);
																					})
																			}
																		</TableBody>
																	</Table>
																</TableContainer>
																<TablePagination
																	rowsPerPageOptions={[
																		7,
																		14,
																		21,
																		{ label: "Todos", value: Number.MAX_VALUE },
																	]}
																	component="div"
																	colSpan={7}
																	count={visibleRowsFinalizado.length}
																	rowsPerPage={rowsPerPage}
																	page={page}
																	labelRowsPerPage="Linhas por pagina"
																	SelectProps={{
																		inputProps: {
																			"aria-label": "rows per page",
																		},
																		native: false,
																	}}
																	onPageChange={handleChangePage}
																	onRowsPerPageChange={handleChangeRowsPerPage}
																	ActionsComponent={TablePaginationActions}
																/>
															</ThemeProvider>
														</Paper>
													</TabPanel>
												</Item>
											</Grid>
										</Grid>
									</ThemeProvider>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>

				<Modal
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "50%", md: "50%" },
							bgcolor: "white",
							borderRadius: 8,
						}}
					>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										border: "none",
										boxShadow: "none",
										display: "flex",
										alignItems: "start",
									}}
								>
									<h2 className={styles.modalContentTitle}>Finalizar safra</h2>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										border: "none",
										boxShadow: "none",
										display: "flex",
										alignItems: "start",
									}}
								>
									<h2 className={styles.modalContenteError}>{message}</h2>
								</Item>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Item
									sx={{
										border: "none",
										boxShadow: "none",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<div
										className={
											error === false
												? styles.registrationInput
												: styles.registrationInputRed
										}
									>
										<InputData
											placeholder="Data"
											onDateChange={(e) => setFinalizedDate(e)}
											classN={styles.input__data}
											withPortal={true}
										/>
									</div>
								</Item>
							</Grid>

							<Grid item xs={12} sm={12} md={12}>
								<Item sx={stylesItem}>
									<Grid
										container
										rowSpacing={1}
										columnSpacing={{ xs: 0, sm: 0, md: 0 }}
										sx={{
											display: "flex",
											alignItems: "center",
											justifyContent: "end",
											flexDirection: {
												xs: "column-reverse",
												sm: "column-reverse",
												md: "row",
											},
										}}
									>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="reset"
													className={styles.registrationButtonCancelar}
													onClick={handleCloseModal}
												>
													Cancelar
												</button>
											</Item>
										</Grid>
										<Grid item xs={12} sm={12} md={3}>
											<Item sx={stylesItem}>
												<button
													type="button"
													onClick={handleFinalize}
													className={styles.registrationButtoncadastrar}
												>
													Finalizar
												</button>
											</Item>
										</Grid>
									</Grid>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}

export default CropSeason;
