//material ul imports
import AddIcon from "@mui/icons-material/Add";
import { Autocomplete, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { ptBR } from "@mui/material/locale";
import Paper from "@mui/material/Paper";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import HelpButtonInput from "../../../components/HelpButtonInput";
import RegisterArea from "../../../components/RegistrationArea";
import TopMenu from "../../../components/TopMenu";
import { CultivationService } from "../../../service/cultivation_service";
import { FarmService } from "../../../service/farm_service";
import { MeasurementUnitService } from "../../../service/measurement_unit_service";
import { captalizeFirstLetterEacheWord } from "../../../utils/utils";
import styles from "./CultureCad.module.css";

import "./style.css";
import Loading from "../../../components/Loading";

const theme = createTheme(
	{
		palette: {
			primary: { main: "#1976d2" },
		},
	},
	ptBR
);
const Item = styled(Paper)(({ theme }) => ({
	backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: "center",
	color: theme.palette.text.secondary,
}));
const styleAdd = {
	color: "#1A877F",
	margin: "0 0 0 5px",
};
const stylesItem = {
	bgcolor: "rgba(0, 0, 0, 0)",
	border: "none",
	boxShadow: "none",
	display: "flex",
	alignItems: "center",
	justifyContent: "start",
	width: "100%",
	m: 0,
	p: 0,
};
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	width: 100,
	transform: "translate(-50%, -50%)",
};
function CultureCad() {
	const [searchParams] = useSearchParams();
	const [farm, setFarm] = useState({packages:[]});
	const [open, setOpen] = useState(false);
	const [farmPackage, setFarmPackage] = useState({});
	const [cultures, setCultures] = useState([]);
	const [allMeasurementUnits, setAllMeasurementUnits] = useState([]);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("info");
	const [errorMessageCulture, setErrorMessageCulture] = useState(false);
	const [errorMessageItem, setErrorMessageItem] = useState(false);
	const [id, setId] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const cultivationService = new CultivationService();
	const measurementUnitService = new MeasurementUnitService();
	const farmService = new FarmService();

	useEffect(() => {
		initData();
	}, []);

	const initData = async () => {
		const cultivationParams = {
			page: 1,
			limit: 100000,
			sortBy: JSON.stringify([
				{
					cultura: "asc"
				},
				{
					variedade: "asc"
				}
			])
		};

		const cultivations = await cultivationService.get(cultivationParams);
		setCultures(cultivations);

		let measurementUnits = await measurementUnitService.get();
		measurementUnits = measurementUnits.filter((measurementUnit) => measurementUnit.cultivo);
		setAllMeasurementUnits(measurementUnits);

		const farmId = searchParams.get("id");

		if (farmId !== null) {
			setId(farmId);
			const farmToEdit = await farmService.getById(farmId);
			const cultivation = {
				firstLetter: farmToEdit.cultivo.cultura[0].toUpperCase(),
				title: captalizeFirstLetterEacheWord(`${farmToEdit.cultivo.cultura} ${farmToEdit.cultivo.variedade ?? ""}`),
				id: farmToEdit.cultivo.id,
			};
			farmToEdit.cultivo = cultivation;
			setFarm(farmToEdit);
		}
		setIsLoading(false);
	};

	const handleAdd = () => {
		if (
			farmPackage.nome && 
			farmPackage.nome.length > 0 &&
			farmPackage.quantidade &&
			farmPackage.quantidade > 0 &&
			farmPackage.unidadeMedidaId
		) {
			const newItems = [...farm.packages, farmPackage];

			setFarm({...farm, packages: newItems});
			setFarmPackage({
				nome: null,
				unidadeMedidaId: null,
				quantidade:null
			});
		}
	};

	const handleRemove = (index) => {
		const newItems = farm.packages.filter((item, itemIndex) => itemIndex !== index);

		setFarm({...farm, packages: newItems});
	};

	const handleOpenEmbalagem = () => {
		setOpen(true); //abre o modal
	};
	const handleCloseEmbalagem = () => {
		setOpen(false); //fecha o modal
		setFarmPackage({
			nome: null,
			unidadeMedidaId: null,
			quantidade:null
		});
	};

	const handleCloseAddEmbalagem = () => {
		handleAdd(); //adiciona o item no array
		setOpen(false); //fecha o modal
	};
	const validationSave = async () => {
		if (farm.cultivoId !== undefined && (id !== null || farm.packages.length > 0)) {
			await handleSave();
			setTypeMessage("success");
			setMessage("cadastro salvo!!!");
			setErrorMessageCulture(false);
			setErrorMessageItem(false);
			return true;
		} else {
			let message = "Um ou mais campos vazios! - (";
			if (farm.cultivoId) {
				message += "Cultura, ";
				setErrorMessageCulture(true);
			} else {
				setErrorMessageCulture(false);
			}
			if (id === null && farm.packages.length === 0) {
				message += "Embalagem, ";
				setErrorMessageItem(true);
			} else {
				setErrorMessageItem(false);
			}
			message += ")";
			setTypeMessage("error");
			setMessage(message);
			return false;
		}
	};
	const handleSave = async () => {
		const propertyId = localStorage.getItem("propertyId");
		if (!id) {
			const farmPackages = farm.packages.map((item) => {
				return {
					name: item.nome,
					quantity: item.quantidade,
					measureUnityId: item.unidadeMedidaId
				};
			});
			const farmToSave = {
				barCode: farm.codigoDeBarra,
				cultivationId: farm.cultivoId,
				propertyId: propertyId,
				packages: farmPackages
			};

			await farmService.save(farmToSave);
		}else{
			const farmToEdit = {
				id: farm.id,
				barCode: farm.codigoDeBarra,
				hide: false,
				cultivationId: farm.cultivoId,
			};
			await farmService.update(farmToEdit);
		}
	};

	const data = cultures.map((item) => {
		return { title: captalizeFirstLetterEacheWord(`${item.cultura} ${item.variedade ?? ""}`), id: item.id };
	});
	const options = data.map((option) => {
		const firstLetter = option.title[0].toUpperCase();
		return {
			firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
			...option,
		};
	});
	
	return isLoading ? 
		(
			<div>
				<Loading /> 
			</div>
		) : (
			<div>
				<TopMenu open={["register", "culture"]} help="cultureCad" />
				{/* href defini a volta  title defini o titulo da pagina*/}
				<Box component="main" sx={{ p: 0, mt: 12 }}>
					<Box sx={{ width: "100%", px: 3 }}>
						<Grid
							container
							rowSpacing={0}
							columnSpacing={{ xs: 1, sm: 2, md: 3 }}
							sx={{ display: "flex", width: "100%" }}
						>
							<Grid item xs={12} sm={12} md={12}>
								<Item>
									<RegisterArea
										title="Cadastro de Cultura"
										href="/culturas"
										salveFunc={validationSave}
										typeMessage={typeMessage}
										message={message}
									>
										{/* inputs da pagina de resgitro */}
										<Grid
											container
											rowSpacing={1}
											columnSpacing={{ xs: 1, sm: 2, md: 3 }}
										>
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												sx={{
													display: "flexbox",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<div
													className={
														errorMessageCulture
															? styles.errControl
															: styles.noErrControl
													}
												>	<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,backgroundColor:"white",
																visibility:(farm.cultivo ? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
																Selecionar Cultura/Variedade
														</Typography>
		
														<p
															className={
																errorMessageCulture
																	? styles.errText
																	: styles.noErrText
															}
														>
														*
														</p>
														<ThemeProvider theme={theme}>
															<Autocomplete
																options={options.sort(
																	(a, b) =>
																		-b.firstLetter.localeCompare(a.firstLetter)
																)}
																groupBy={(option) => option.firstLetter}
																getOptionLabel={(option) => option.title}
																sx={{ width: "100%", border: "none" }}
																value={farm.cultivo || null}
																onChange={(event, newValue) => {
																	setFarm({...farm, cultivoId: newValue.id, cultivo: newValue});
																}}
																renderInput={(params) => (
																	<div ref={params.InputProps.ref}>
																		<input
																			type="text"
																			{...params.inputProps}
																			placeholder="Selecionar Cultura/Variedade"
																			className={
																				styles.selectInputPlaceholder
																			}
																		/>
																	</div>
																)}
															/>
														</ThemeProvider>
													</Box>
												</div>
											</Grid>
											<Grid
												item
												xs={12}
												sm={12}
												md={6}
												sx={{
													display: "flexbox",
													justifyContent: "center",
													alignItems: "center",
												}}
											>
												<div className={styles.helpPlusInput}>
													<	Box sx={{
														border: "1px solid rgba(0,0,0,0.3)",
														borderRadius: "8px",
														width: "100%",
														display: "flex",
														justifyContent: "flex-start",
														flexDirection: "column",
														alignItems: "flex-start",
														px: 1,
														pr:2,
														position: "relative",
														m:0
													}}>
														<Typography 
															variant="caption" 
															display="block" 
															gutterBottom 
															sx={{
																top:-11,
																position:"absolute",
																px:1,
																backgroundColor:"white",
																visibility:(farm.barCode && farm.barCode !== ""? "visible" : "hidden"),
																transition:"all 0.5s ease"
															}} 
														>
																Código de barras
														</Typography>
		
														<input 
															placeholder="Código de barras" 
															className={styles.selectInputPlaceholder} 
															value={farm.codigoDeBarra} 
															onChange={(e)=>setFarm({...farm, codigoDeBarra: e.target.value})}
														/>
													</Box>
													<HelpButtonInput
														title={"CÓDIGO DE BARRAS"}
														text={
															"O código a ser registrado nessa campo deve ser o código do produto registrado junto ao Sistema GS1 e deve ser do tipo ITF-14 ou GS1-128. Para mais informações acesse: "
														}
														link={"https://www.gs1br.org/"}
													/>
												
												</div>
											</Grid>
											{id === null && (<Grid item xs={12} sm={12} md={12}>
												<Item sx={stylesItem}>
													<div className={styles.registrationTitle}>
														<a
															onClick={handleOpenEmbalagem}
															className={styles.addBtn}
														>
															Adicionar Embalagem
															<AddIcon style={styleAdd} />
														</a>
													</div>
													<p
														className={
															errorMessageItem ? styles.errText : styles.noErrText
														}
													>
														Adicione pelo menos 1 Embalagem *
													</p>
												</Item>
											</Grid>)}
											{id === null && (<Grid item xs={12} sm={12} md={12}>
												<Item sx={{ minHeight: "45px", minWidth: "50vw" }}>
													<Grid
														container
														rowSpacing={1}
														columnSpacing={{ xs: 1, sm: 2, md: 3 }}
														sx={{
															display: "flex",
															alignItems: "center",
															justifyContent: "start",
															width: "100%",
														}}
													>
														{farm.packages.map((item, index) => (
															<Grid item key={index}>
																<Item sx={stylesItem}>
																	<div className={styles.itemNameCuture}>
																		<button
																			className={styles.itemButtonCuture}
																			type="button"
																			id={item.itemId}
																			onClick={() => handleRemove(index)}
																		>
																			X
																		</button>
																		{item.nome +
																			"(" +
																			item.quantidade +
																			" " +
																			item.unidadeMedida.sigla +
																			")"}
																	</div>
																</Item>
															</Grid>
														))}
													</Grid>
												</Item>
											</Grid>)}
										</Grid>
									</RegisterArea>
								</Item>
							</Grid>
						</Grid>
					</Box>
				</Box>
				<Modal
					open={open}
					onClose={handleCloseEmbalagem}
					aria-labelledby="parent-modal-title"
					aria-describedby="parent-modal-description"
				>
					<Box
						sx={{
							...style,
							width: { xs: "90%", sm: "80%", md: "70%" },
							bgcolor: "white",
							borderRadius: "8px",
							p: 3,
						}}
					>
						<Grid
							container
							rowSpacing={2}
							columnSpacing={{ xs: 0, sm: 2, md: 3 }}
							sx={stylesItem}
							className={styles.registrationInputModal}
						>
							<Grid item xs={12} sm={12} md={12}>
								<p className={styles.registrationTitle}>
									Cadastro de embalagem
								</p>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr: 2,
									py: 1,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-15,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(farmPackage.nome ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Nome da embalagem
									</Typography>
		
									<input
										value={farmPackage.nome}
										onChange={(event) => setFarmPackage({...farmPackage, nome:event.target.value})}
										placeholder="Nome da embalagem"
										type="text"
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6} md={6} lg={4}>
								<	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr: 2,
									py: 1,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-15,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(farmPackage.quantidade ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Quantidade
									</Typography>
		
									<input
										type="number"
										placeholder="Quantidade"
										value={farmPackage.quantidade}
										onChange={(event) => setFarmPackage({...farmPackage, quantidade: Number(event.target.value)})}
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={4}>
								<Box	Box sx={{
									border: "1px solid rgba(0,0,0,0.3)",
									borderRadius: "8px",
									width: "100%",
									display: "flex",
									justifyContent: "flex-start",
									flexDirection: "column",
									alignItems: "flex-start",
									px: 1,
									pr: 2,
									py: 1,
									position: "relative",
									m:0
								}}>
									<Typography 
										variant="caption" 
										display="block" 
										gutterBottom 
										sx={{
											top:-15,
											position:"absolute",
											px:1,
											backgroundColor:"white",
											visibility:(farmPackage.unidadeMedidaId ? "visible" : "hidden"),
											transition:"all 0.5s ease"
										}} 
									>
										Unidade de medida
									</Typography>
									<select
										id="medida"
										onChange={(event) => {
											const measurementUnit = allMeasurementUnits.find((measurementUnit) => measurementUnit.id === event.target.value);
											setFarmPackage({...farmPackage, unidadeMedidaId: event.target.value, unidadeMedida: measurementUnit});
										}}
										value={farmPackage.unidadeMedidaId}
										className={style.selectInputPlaceholder}
									>
										<option value={"-1"}>
											{farmPackage.unidadeMedidaId
												? "---------------"
												: "Selecionar unidade de medida"}
										</option>
										{allMeasurementUnits.map((item, index) => {
											return (
												<option value={item.id} key={index}>
													{item.sigla}
												</option>
											);
										})}
									</select>
								</Box>
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Grid
									container
									rowSpacing={2}
									columnSpacing={{ xs: 0, sm: 0, md: 0 }}
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "end",
										flexDirection: {
											xs: "column-reverse",
											sm: "column-reverse",
											md: "row",
										},
										pt: 2,
									}}
								>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												type="button"
												onClick={handleCloseEmbalagem}
												className={styles.registrationButtonCancelar}
											>
												Cancelar
											</button>
										</Item>
									</Grid>
									<Grid item xs={12} sm={12} md={3}>
										<Item sx={stylesItem}>
											<button
												className={styles.registrationButtoncadastrar}
												type="button"
												onClick={handleCloseAddEmbalagem}
											>
												Cadastrar
											</button>
										</Item>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Modal>
			</div>
		);
}

export default CultureCad;
